import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/apm'
import { hotjar } from 'react-hotjar'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router } from 'react-router-dom'
import {
  facebookPixelInit,
  geocodeInit,
  snapPixelInit,
  twitterPixelInit,
} from './utils/utils'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: 'otlob-frontend@' + process.env.npm_package_version,
    integrations: [new Integrations.Tracing() as any],
    tracesSampleRate: 0.4, // Be sure to lower this in production
  })

  hotjar.initialize(1955349, 6)

  if (process.env.REACT_APP_TAG_MANAGER) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_TAG_MANAGER,
    }
    TagManager.initialize(tagManagerArgs)
  }
}

facebookPixelInit()
snapPixelInit()
twitterPixelInit()
geocodeInit()

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
