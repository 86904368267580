import React from 'react'
import { ICoords } from '../interfaces'
// @ts-ignore
import SnapPixel from 'react-snapchat-pixel'
// @ts-ignore
import TwitterPixel from 'react-twitter-pixel'
import FacebookPixel from 'react-facebook-pixel'
import { Restaurant } from '../api'
import Geocode from 'react-geocode'

export const geocodeInit = () => {
  if (process.env.REACT_APP_GOOGLE_MAPS_GEOCODING_API) {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_GEOCODING_API)
    Geocode.setLanguage('en')
    if (process.env.NODE_ENV === 'development') {
      Geocode.enableDebug()
    }
  }
}

export const restaurantHasFeature = (
  restaurant: Restaurant,
  feature: string
) => {
  const res = restaurant.subscriptions.map((subscription) =>
    subscription.package.features.filter((feat) => feat.code === feature)
  )
  return res.filter((r) => r.length > 0).length > 0
}

export const isSafari = () => {
  return (
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1
  )
}

type AsyncStatus = 'idle' | 'loading' | 'success' | 'error'

export const useAsync = <ReturnType, Params>(
  asyncFunction: (params: Params) => Promise<ReturnType>
): [
  (params: Params) => Promise<ReturnType>,
  { status: AsyncStatus; data: ReturnType | null; error: Error | null }
] => {
  const [status, setStatus] = React.useState<AsyncStatus>('idle')
  const [data, setData] = React.useState<ReturnType | null>(null)
  const [error, setError] : any = React.useState<Error | null>(null)

  const execute = React.useCallback(
    async (params: Params) => {
      setStatus('loading')
      setData(null)
      setError(null)
      try {
        const response = await asyncFunction(params)
        setData(response)
        setStatus('success')
        return response
      } catch (error) {
        setError(error)
        setStatus('error')
        throw error
      }
    },
    [asyncFunction]
  )

  return [execute, { status, data, error }]
}

export const useLocalStorageState = <T>(
  key: string,
  initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const localStorageValue = localStorage.getItem(key)
  const [state, setState] = React.useState<T>(() =>
    localStorageValue ? JSON.parse(localStorageValue) : initialValue
  )

  React.useEffect(() => {
    if (state !== undefined) localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  React.useEffect(() => {
    const localStorageValue = localStorage.getItem(key)
    if (localStorageValue) setState(JSON.parse(localStorageValue))
  }, [key])

  return [state, setState]
}

export const getCoordinateFromString = (coordinateString: string): ICoords => {
  const coordinateArray = coordinateString
    .split(',')
    .map((coordinate) => Number(coordinate))
  return { latitude: coordinateArray[0], longitude: coordinateArray[1] }
}

export const isUsingRestaurantCustomDomain = () => {
  if (process.env.REACT_APP_RESTAURANT_SLUG) {
    return true
  }

  const otlobDomains = process.env.REACT_APP_OTLOB_DOMAINS?.split(',') ?? []
  const { hostname } = window.location
  if (otlobDomains.includes(hostname)) return false
  return hostname.split('.').length >= 2
}

export const getRestaurantSlugFromSubdomain = () => {
  if (process.env.REACT_APP_RESTAURANT_SLUG) {
    return process.env.REACT_APP_RESTAURANT_SLUG
  }

  const { hostname } = window.location
  return hostname.split('.')[0]
}

export const facebookPixelInit = () => {
  if (process.env.REACT_APP_FACEBOOK_PIXEL_IDS) {
    process.env.REACT_APP_FACEBOOK_PIXEL_IDS.split(',').forEach(
      (facebookPixelId) => {
        FacebookPixel.init(facebookPixelId, undefined, {
          autoConfig: false,
          debug: process.env.NODE_ENV === 'development',
        })
      }
    )
  }
}

export const facebookPixelTrack = (title: string, data?: any) => {
  if (process.env.REACT_APP_FACEBOOK_PIXEL_IDS) {
    process.env.REACT_APP_FACEBOOK_PIXEL_IDS.split(',').forEach(
      (facebookPixelId) => {
        FacebookPixel.trackSingle(facebookPixelId, title, data)
      }
    )
  }
}

export const snapPixelInit = () => {
  if (process.env.REACT_APP_SNAP_PIXEL_ID) {
    SnapPixel.init(process.env.REACT_APP_SNAP_PIXEL_ID, undefined, {
      autoConfig: false,
      debug: process.env.NODE_ENV === 'development',
    })
  }
}

export const snapPixelTrack = (title: string, data?: any) => {
  /* 
  TODO: Active the snap pixel 
  there is a bug that cause snap pixel throw the following error :
  Uncaught TypeError: Cannot read property 'constructor' of undefined
  */
  // SnapPixel.track(title, data)
}

export const twitterPixelInit = () => {
  if (process.env.REACT_APP_TWITTER_PIXEL_ID) {
    TwitterPixel.init(process.env.REACT_APP_TWITTER_PIXEL_ID, undefined, {
      autoConfig: false,
      debug: process.env.NODE_ENV === 'development',
    })
  }
}

export const twitterPixelTrack = (title: string, data?: any) => {
  TwitterPixel.track(title, data)
}
